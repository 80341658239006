/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'x5if6nQ3ts5fSsGXQQXttr.png': { uri: '/images/x5if6nQ3ts5fSsGXQQXttr.png' },
'xeYt9sFe8Ca2J9WUSCWFNS.png': { uri: '/images/xeYt9sFe8Ca2J9WUSCWFNS.png' },
'5Bu5GLL5vt4QPUPPigDax3.png': { uri: '/images/5Bu5GLL5vt4QPUPPigDax3.png' },
'uC8WN9SJrNuPPm3zxie5oy.jpg': { uri: '/images/uC8WN9SJrNuPPm3zxie5oy.jpg' },
'modntFqP4vK73he1scgmNq.png': { uri: '/images/modntFqP4vK73he1scgmNq.png' },
'oztEDPJWDgCSwyPSZSM8wQ.png': { uri: '/images/oztEDPJWDgCSwyPSZSM8wQ.png' },
'4PL9yJZH1ruSaumEsNG6fM.png': { uri: '/images/4PL9yJZH1ruSaumEsNG6fM.png' },
'kSUaz9FcrBuiGojVgdt29E.png': { uri: '/images/kSUaz9FcrBuiGojVgdt29E.png' },
'r3GPDmSwkipmQmRdCiX9Ze.png': { uri: '/images/r3GPDmSwkipmQmRdCiX9Ze.png' },
'2SW9Fu4nTdG5BSqJyR7ysX.png': { uri: '/images/2SW9Fu4nTdG5BSqJyR7ysX.png' },
'32btbcgwwZAnLB1f3B4FHa.png': { uri: '/images/32btbcgwwZAnLB1f3B4FHa.png' },
'qpw43UsYcm37dTtYi7HLys.png': { uri: '/images/qpw43UsYcm37dTtYi7HLys.png' },
'otcsrnEWM4fAk1yL9cyyyS.png': { uri: '/images/otcsrnEWM4fAk1yL9cyyyS.png' },
'cnoimKYeMBAn9zX7SbiRVU.png': { uri: '/images/cnoimKYeMBAn9zX7SbiRVU.png' },
'gscSTbnWwPP3y1A5PSpNkA.png': { uri: '/images/gscSTbnWwPP3y1A5PSpNkA.png' },
'tvmN8EhWHpAjXsFVBBK4jv.png': { uri: '/images/tvmN8EhWHpAjXsFVBBK4jv.png' },
'nTVxhoVNwWeNAUPyt9gCgm.png': { uri: '/images/nTVxhoVNwWeNAUPyt9gCgm.png' },
'iBDphvpvL4rhtXCpLUaTue.png': { uri: '/images/iBDphvpvL4rhtXCpLUaTue.png' },
'pJsssqxg8GQHjowviYicxJ.png': { uri: '/images/pJsssqxg8GQHjowviYicxJ.png' },
'nvGfsa1nr63jvDqLRyzuoM.png': { uri: '/images/nvGfsa1nr63jvDqLRyzuoM.png' },
'ncGwQGecm2RQHEyGS991LN.png': { uri: '/images/ncGwQGecm2RQHEyGS991LN.png' },
'cSTtgsj8PyWcFWbmuDiHzH.png': { uri: '/images/cSTtgsj8PyWcFWbmuDiHzH.png' },
'2FEf7ckEXg1iFEAVAFXCEW.png': { uri: '/images/2FEf7ckEXg1iFEAVAFXCEW.png' },
'77RMSHSrvsAQR9KLdjGy2s.png': { uri: '/images/77RMSHSrvsAQR9KLdjGy2s.png' },
'fgXDiVJ6X2vXcYLYThM7PE.png': { uri: '/images/fgXDiVJ6X2vXcYLYThM7PE.png' },
'pU5YumeC1jnEmjt5DBU7mK.png': { uri: '/images/pU5YumeC1jnEmjt5DBU7mK.png' },
'2e32LXR1oNy9ZwPEwkzjMa.png': { uri: '/images/2e32LXR1oNy9ZwPEwkzjMa.png' },
'6AXGPZsdYCfwdfeDnqh1jn.png': { uri: '/images/6AXGPZsdYCfwdfeDnqh1jn.png' },
'fBixAdaykjrY4MUgaDrC56.png': { uri: '/images/fBixAdaykjrY4MUgaDrC56.png' },
'aQnfLyvdEhsudpoY1NViJi.png': { uri: '/images/aQnfLyvdEhsudpoY1NViJi.png' },
'qnx2MTGHmnYxkHoKb4uLs7.png': { uri: '/images/qnx2MTGHmnYxkHoKb4uLs7.png' },
'hT4vwGaGe81hDEVZADdgcQ.webp': { uri: '/images/hT4vwGaGe81hDEVZADdgcQ.webp' },
'atRhsJAXh2CMAsfD4bJbpL.png': { uri: '/images/atRhsJAXh2CMAsfD4bJbpL.png' },
'64HjBVn1RBy4wmZtTJFpiv.png': { uri: '/images/64HjBVn1RBy4wmZtTJFpiv.png' },
'a4Jppeq3s7qAm9M1UYBYYA.jpg': { uri: '/images/a4Jppeq3s7qAm9M1UYBYYA.jpg' },
'2bfXTYfeKSj5UxtmhPESo2.jpg': { uri: '/images/2bfXTYfeKSj5UxtmhPESo2.jpg' },
'jQVgy8AUi4UAKUsrK1WpEQ.jpg': { uri: '/images/jQVgy8AUi4UAKUsrK1WpEQ.jpg' },
'5h6GybBwLfwpVvcNZETYTf.jpg': { uri: '/images/5h6GybBwLfwpVvcNZETYTf.jpg' },
'jPKXsh5JPgXqDE9xDAnZYE.jpg': { uri: '/images/jPKXsh5JPgXqDE9xDAnZYE.jpg' },
'3R3Cvdi6VqAAHQcP98GDd5.png': { uri: '/images/3R3Cvdi6VqAAHQcP98GDd5.png' },
'pxgvzanvkrRBLcZ5Epo6Ev.png': { uri: '/images/pxgvzanvkrRBLcZ5Epo6Ev.png' },
'gkQSvbByz1RcJZdrf3G2mT.jpg': { uri: '/images/gkQSvbByz1RcJZdrf3G2mT.jpg' },
'2Z9bwpY4H4jrqHCEyLXP47.jpg': { uri: '/images/2Z9bwpY4H4jrqHCEyLXP47.jpg' }
}

export default imageStaticSourcesByFileName
